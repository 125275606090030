import React from 'react'
import SEO from '../components/seo'
import Layout from '../components/layout'
import { DiscussionEmbed } from "disqus-react"
import CarModelCard from '../components/car-model-card'
import Logo from '../../static/icon-round-allstocksociety.png'
import './car-mods-page-template.css'



export default function CarModTemplate( props ) {

 

  const { pageContext } = props
  const { pageContent } = pageContext

  const ogSlug = props.path


  // SEO prep
  const seoTitle = pageContent.make + " Mods Guide"

  // Disqus prep
  const title = pageContent.make + " Discussion";
  const slug = pageContent.make + "-slug";

  // "all-stock-society"
  // 

  const disqusConfig = {
    shortname: process.env.GATSBY_DISQUS_NAME,
    config: { identifier: slug, title }
  }

  return (

    <Layout>

      <SEO title={seoTitle} description={pageContent.excerpt} image={Logo} article={false} />

      <div className="main-heading-box">
        <h1 className="main-heading-text">{pageContent.make} Mods Guide</h1>
        <p>{pageContent.excerpt}</p>
      </div>

      <div> 
        <h2>Popular {pageContent.make} Models</h2>

        <div className="grid-container">
          {pageContent.models.map((data, index) => {
            return <CarModelCard key={`models_${index}`} data={data} index={index} props={pageContent} slug={ogSlug}/>
          })}
        </div>

      </div>

      <div>
        <h3>A Bit of History</h3>
        <p>{pageContent.history}</p>
      </div>

      <hr className="hr-comments"/>

      <DiscussionEmbed {...disqusConfig} />

    </Layout>

  )
}