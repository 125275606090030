import React from 'react'
import { Link } from 'gatsby'

import './car-model-card.css'


export default function CarModelCard ({props, index, data, slug}) {

  //data is from model
  //props is entire make
  // props.models[index] is the same as data for that specific model

  // console.log(data)
  // console.log(props)
  // console.log(props.models[index])

  // const preSlug = slug + ""
  const fullSlug = slug + data.path
  console.log(fullSlug)

  return (
    <div className="car-model-card">
      <Link to={fullSlug} className="car-model-card-link">
        <div className="model-desc-box">
          <p className="model-desc-title">{data.content.model}</p>
          <p className="model-desc-text" key={`content_item_${index}`}>The {data.content.model}  is a {data.content.class} from {props.origin} automaker {props.make}</p>
        </div>
      </Link>
    </div>
  )
}